'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

function _interopDefault (ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex['default'] : ex; }

var React = _interopDefault(require('react'));

const viewportContext = React.createContext({});
const ViewportProvider = ({ children }) => {
    const [width, setWidth] = React.useState(window.innerWidth);
    const [height, setHeight] = React.useState(window.innerHeight);
    const handleWindowResize = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    };
    const getBreakPoint = (width) => {
        if (width >= 1920)
            return 'xl';
        if (width >= 1280)
            return 'lg';
        if (width >= 960)
            return 'md';
        if (width >= 600)
            return 'sm';
        return 'xs';
    };
    React.useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);
    return (React.createElement(viewportContext.Provider, { value: { width, height, bp: getBreakPoint(width) } }, children));
};
const useViewport = () => {
    const { width, height, bp } = React.useContext(viewportContext);
    return { width, height, bp };
};

exports.ViewportProvider = ViewportProvider;
exports.useViewport = useViewport;
